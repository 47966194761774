import cls from 'classnames';
import React from 'react';
import formatNumber from 'Support/formatNumber';
import useUser from 'Support/Hooks/useUser';

const Price = ({ className = '', product }) => {
  const user = useUser();

  if (!className.includes('font-')) {
    className = cls('font-normal', className);
  }

  return (
    <div className={cls('text-gray-900', className)}>
      {user?.showPrices && !product.is_read_only && (
        <>
          $
          {product.has_discount ? (
            <>
              {formatNumber(product.discounted_price)} <span className="text-gray-500 line-through">${formatNumber(product.base_price)}</span>
            </>
          ) : (
            formatNumber(product.base_price)
          )}
        </>
      )}
    </div>
  );
};

export default Price;
