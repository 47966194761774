import { Link, usePage } from '@inertiajs/react';
import cls from 'classnames';
import { PrimaryButton } from 'Components/Buttons/PrimaryButton';
import PencilIcon from 'Components/Icons/PencilIcon';
import SideBarToggleIcon from 'Components/Icons/SideBarToggleIcon';
import TrashIcon from 'Components/Icons/TrashIcon';
import Code from 'Components/Products/Code';
import CommentField from 'Components/Products/CommentField';
import Price from 'Components/Products/Price';
import QuantityField from 'Components/Products/QuantityField';
import StockLevels from 'Components/Products/StockLevels';
import Mask from 'Layouts/_partials/Mask';
import React, { useContext, useMemo, useState } from 'react';
import { CartContext } from 'Support/Contexts/CartContext';
import formatNumber from 'Support/formatNumber';
import useUser from 'Support/Hooks/useUser';
import useCartStore from 'Support/Stores/useCartStore';
import route from 'ziggy-js';

const Product = ({ product }) => {
  const [addingComment, setAddingComment] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { removeItem } = useCartStore();

  return (
    <div className="relative py-4">
      <div
        className={cls(
          'absolute inset-0 bottom-4 top-4 z-50 flex flex-col justify-between rounded-xl bg-primary p-4 text-xs text-white',
          !deleting && 'hidden',
        )}
      >
        <div className="pb-2">
          Remove{' '}
          <span className="font-semibold">
            {product.quantity_in_cart} {product.title}
          </span>{' '}
          from your cart?
        </div>

        <div className="flex justify-between">
          <button onClick={() => setDeleting(false)}>Cancel</button>

          <button onClick={() => removeItem(product)}>Remove</button>
        </div>
      </div>

      <div className="flex gap-5">
        <div className="relative flex h-16 w-16 shrink-0 items-center justify-center rounded-xl border border-gray-200">
          <Link href={route('browse.catalogue.product', product.code)}>
            <img src={product.main_image} alt={product.title + ' image'} />
          </Link>

          <div className="absolute -bottom-2 -right-2">
            <button
              className="flex h-10 w-10 items-center justify-center rounded-full border border-gray-400 bg-white"
              onClick={() => setAddingComment(!addingComment)}
              style={{ boxShadow: '0px 1.5px 4px -1px #0a090b12' }}
            >
              <PencilIcon />
            </button>

            {!!product.comments_in_cart?.length && <div className="absolute right-1 top-0 h-2.5 w-2.5 rounded-full bg-primary" />}
          </div>
        </div>

        <div className="grow">
          <div className="mb-1 flex items-start justify-between gap-5 font-semibold text-gray-900">
            <Link href={route('browse.catalogue.product', product.code)} className="max-w-48 truncate text-xs">
              {product.title}
            </Link>

            <button type="button" onClick={() => setDeleting(true)}>
              <TrashIcon />
            </button>
          </div>

          <Code product={product} />

          <StockLevels product={product} />

          <div className="mt-2 flex items-center justify-between">
            <Price product={product} />

            <QuantityField product={product} noToZero />
          </div>
        </div>
      </div>

      <div className={cls('pt-2', !addingComment && 'hidden')}>
        <CommentField product={product} setAddingComment={setAddingComment} />
      </div>
    </div>
  );
};

export const calculateProductSubtotal = (product) => {
  const discountInEffect =
    product.has_discount && (product.discount_type === 'clearance' || parseInt(product.quantity_in_cart) >= parseInt(product.discount_min_quantity));
  const price = discountInEffect ? product.discounted_price : product.base_price;
  return parseFloat(price) * parseInt(product.quantity_in_cart);
};

const Cart = () => {
  const { cartCollapsed, setCartCollapsed, showCart } = useContext(CartContext);
  const { cart, itemCount } = useCartStore();
  const user = useUser();

  const { noSideCart } = usePage().props;

  const subTotal = useMemo(
    () => Object.values(cart).reduce((total, item) => parseInt(total) + parseFloat(calculateProductSubtotal(item)), 0),
    [itemCount],
  );

  const gst = subTotal * 0.15;

  if (!cart || !showCart || itemCount < 1) {
    setCartCollapsed(true);
    return <></>;
  }

  return noSideCart ? (
    <></>
  ) : (
    <>
      <Mask setShow={() => setCartCollapsed(true)} show={!cartCollapsed} className="2xl:hidden" />

      <div className="fixed bottom-0 right-0 top-0 z-[100] h-full shrink-0 overflow-hidden rounded-l-3xl bg-white shadow-2xl md:block 2xl:sticky 2xl:rounded-none 2xl:shadow-none">
        <div
          className={cls(
            'relative flex h-screen max-w-[100vw] flex-col justify-between border-x py-8 transition-all duration-500',
            cartCollapsed ? 'w-0 p-0 2xl:w-20 2xl:px-4' : 'w-96 px-4',
          )}
        >
          <div className={cls('absolute right-4 z-30 flex items-center transition-all duration-500')}>
            <button onClick={() => setCartCollapsed(!cartCollapsed)}>
              <SideBarToggleIcon className={cls('transition-all duration-500', !cartCollapsed && '-rotate-180')} />
            </button>
          </div>

          <div
            className={cls('flex translate-x-2 items-center gap-4 transition-all duration-500', cartCollapsed && 'translate-y-16 rotate-90')}
            style={{
              transformOrigin: '16px',
            }}
          >
            <div className={cls('inline-block h-8 whitespace-nowrap text-3xl font-semibold transition-all duration-500')}>My Cart</div>

            <div
              className={cls(
                'flex h-8 w-8 shrink-0 translate-y-1 items-center justify-center rounded-md bg-primary/15 font-semibold text-primary transition-all duration-500',
                cartCollapsed && '-rotate-90',
                itemCount >= 100 ? (itemCount >= 1000 ? 'text-xs' : 'text-sm') : 'text-xl',
              )}
            >
              {itemCount}
            </div>
          </div>

          <div
            className={cls('mt-8 h-full shrink divide-y overflow-y-auto border-t', cartCollapsed && 'opacity-0')}
            style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
          >
            {Object.values(cart)
              .sort((a, b) => a.code.localeCompare(b.code))
              .map((product) => (
                <Product product={product} key={product.id} />
              ))}
          </div>

          <div className={cls('transition-all duration-500', cartCollapsed && 'opacity-0')}>
            {user?.showPrices && (
              <>
                <div className="my-6 grid h-10 gap-2 font-normal">
                  <div className="flex items-center justify-between">
                    <div>Subtotal:</div>

                    <div className="text-lg font-medium">${formatNumber(subTotal)}</div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div>GST:</div>

                    <div className="text-lg font-medium">${formatNumber(gst)}</div>
                  </div>
                </div>

                <div className="flex items-center justify-between border-t border-dashed border-gray-400 py-6 font-normal">
                  <div>Total:</div>

                  <div className="text-xl font-medium">${formatNumber(subTotal + gst)}</div>
                </div>
              </>
            )}

            <PrimaryButton className="w-full py-6 text-lg">
              <Link href={route('cart.index')}>View Cart</Link>
            </PrimaryButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
